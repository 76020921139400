import React from 'react';
import { flowRight, get } from 'lodash';
import PropTypes from 'prop-types';
import { Version, isHexColor } from 'wix-rich-content-common';
import {
  pluginLinkButton,
  pluginActionButton,
} from 'wix-rich-content-plugin-button/viewer';
import { pluginCodeBlock } from 'wix-rich-content-plugin-code-block/viewer';

import { pluginCollapsibleList } from 'wix-rich-content-plugin-collapsible-list/loadable/viewer';
import { pluginDivider } from 'wix-rich-content-plugin-divider/viewer';
import { pluginFileUpload } from 'wix-rich-content-plugin-file-upload/loadable/viewer';
import { pluginGallery } from 'wix-rich-content-plugin-gallery/loadable/viewer';
import { pluginGiphy } from 'wix-rich-content-plugin-giphy/loadable/viewer';
import { pluginHashtag } from 'wix-rich-content-plugin-hashtag/viewer';
import { pluginHeadersMarkdown } from 'wix-rich-content-plugin-headers-markdown/viewer';
import { pluginHtml } from 'wix-rich-content-plugin-html/loadable/viewer';
import { pluginImage } from 'wix-rich-content-plugin-image/viewer';
import { pluginIndent } from 'wix-rich-content-plugin-indent/viewer';
import { pluginLineSpacing } from 'wix-rich-content-plugin-line-spacing/viewer';
import { pluginLink } from 'wix-rich-content-plugin-link/viewer';
import { pluginLinkPreview } from 'wix-rich-content-plugin-link-preview/loadable/viewer';
import { pluginPoll } from 'wix-rich-content-plugin-social-polls/viewer';
import { pluginTable } from 'wix-rich-content-plugin-table/loadable/viewer';
import {
  pluginTextColor,
  pluginTextHighlight,
} from 'wix-rich-content-plugin-text-color/viewer';
import { pluginVerticalEmbed } from 'wix-rich-content-plugin-vertical-embed/viewer';
import { pluginVideo } from 'wix-rich-content-plugin-video/loadable/viewer';
import {
  TextSelectionToolbar,
  TwitterButton,
} from 'wix-rich-content-text-selection-toolbar';
import { WixRicosViewer, resolveFileUrl } from '@wix/ricos-viewer';
import {
  isExperimentEnabled,
  getIsPostPageBundle,
  getExperiments,
  getCurrentUser,
  resolveId,
  EXPERIMENTS,
} from '@wix/communities-blog-client-common';
import {
  EXPERIMENT_HASHTAG_PAGE_USE_FEED_PAGE,
  EXPERIMENT_RICOS_EMBED_BUTTONS,
  EXPERIMENT_RICOS_POLL_PLUGIN,
  EXPERIMENT_RICOS_BASE_URL,
  EXPERIMENT_RESOLVE_DOWNLOAD_URL_WITH_RICOS,
} from '@wix/communities-blog-experiments';
import { RicosViewerWrapper } from '@wix/communities-blog-client-common/dist/esm/components';

import { connect } from '../../../common/components/runtime-context';
import withDeviceType from '../../../common/hoc/with-device-type';
import withMediaHosts from '../../../common/hoc/with-media-hosts';
import { getAppSettings } from '../../../common/selectors/app-settings-base-selectors';
import { getTwitterEnabled } from '../../../common/selectors/app-settings-selectors';
import { shouldApplyPostDesignInFeed } from '../../../common/selectors/full-post-selectors';
import { getLocale } from '../../../common/selectors/locale-selectors';
import { getIframeSandboxDomain } from '../../../common/selectors/rce-props-selectors';
import { getSection } from '../../../common/selectors/section-selectors';
import Wix from '../../../common/services/wix-sdk-polyfill';
import {
  isSeo as getIsSeo,
  getInstance,
  getUrl,
  isEditor,
  isPreview,
} from '../../../common/store/basic-params/basic-params-selectors';
import { getMetaSiteId } from '../../../common/store/instance-values/instance-values-selectors';
import {
  getBaseUrl,
  getSectionUrl,
} from '../../../common/store/topology/topology-selectors';

const RicosViewer = ({
  initialRawState: content,
  requestFileDownloadUrl,
  requestLogin,
  showMessage,
  fileDownloaded,
  isDemoPost,
  isRicosBaseUrlEnabled,
  isResolveDownloadUrlWithRicosEnabled,
  baseUrl,
  ...rest
}) => {
  const props = {
    ...rest,
    WixRicosViewer,
    pluginCodeBlock,
    pluginDivider,
    pluginLinkButton,
    pluginGallery,
    pluginGiphy,
    pluginHashtag,
    pluginHtml,
    pluginImage,
    pluginLineSpacing,
    pluginIndent,
    pluginLink,
    pluginLinkPreview,
    pluginTable,
    pluginTextColor,
    pluginTextHighlight,
    pluginVideo,
    pluginFileUpload,
    pluginHeadersMarkdown,
    pluginActionButton,
    pluginVerticalEmbed,
    pluginCollapsibleList,
    pluginPoll,
    content,
    rceVersion: Version.currentVersion,
    isHexColor,
    baseUrl: isRicosBaseUrlEnabled ? baseUrl : undefined,
    TextSelectionToolbar,
    TwitterButton,
    Wix,
    isDemoPost,
    actions: {
      requestFileDownloadUrl,
      requestLogin,
      showMessage,
    },
    bi: {
      fileDownloaded,
    },
    ...(isResolveDownloadUrlWithRicosEnabled ? { resolveFileUrl } : {}),
  };

  return <RicosViewerWrapper {...props} />;
};

RicosViewer.propTypes = {
  appSettings: PropTypes.object.isRequired,
  disableTheme: PropTypes.bool.isRequired,
  isSeo: PropTypes.bool.isRequired,
  isInEditor: PropTypes.func.isRequired,
  isInPreview: PropTypes.func.isRequired,
  isPostPageBundle: PropTypes.func.isRequired,
  hashtagNavigate: PropTypes.func.isRequired,
  pageUrl: PropTypes.string.isRequired,
  videoHost: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  instance: PropTypes.string.isRequired,
  metasiteId: PropTypes.string.isRequired,
  sectionUrl: PropTypes.string.isRequired,
  isHashtagPageAsFeedPageEnabled: PropTypes.bool.isRequired,
  isRicosEmbedButtonsEnabled: PropTypes.bool.isRequired,
  isTwitterEnabled: PropTypes.bool.isRequired,
  parentClass: PropTypes.string,
  experiments: PropTypes.object.isRequired,
  isDemoPost: PropTypes.bool,
  shouldApplyPostDesignInFeed: PropTypes.string.isRequired,
  isResolveDownloadUrlWithRicosEnabled: PropTypes.bool.isRequired,
};

RicosViewer.defaultProps = {
  disableTheme: true,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  appSettings: getAppSettings(state),
  isSeo: getIsSeo(state),
  isInEditor: isEditor(state),
  isInPreview: isPreview(state),
  isPostPageBundle: getIsPostPageBundle(state),
  locale: getLocale(state),
  postId: resolveId(ownProps.post),
  instance: getInstance(state),
  pageUrl: getUrl(state),
  hashtagNavigate: actions.navigateWithinBlog,
  baseUrl: getBaseUrl(state),
  section: getSection(state),
  sectionUrl: getSectionUrl(state),
  iframeSandboxDomain: getIframeSandboxDomain(state),
  requestFileDownloadUrl: actions.requestFileDownloadUrlPromisified,
  requestLogin: actions.requestLoginPromisified,
  currentUser: getCurrentUser(state),
  showMessage: actions.showMessage,
  fileDownloaded: actions.fileDownloaded,
  isHashtagPageAsFeedPageEnabled: isExperimentEnabled(
    state,
    EXPERIMENT_HASHTAG_PAGE_USE_FEED_PAGE,
  ),
  isRicosEmbedButtonsEnabled: isExperimentEnabled(
    state,
    EXPERIMENT_RICOS_EMBED_BUTTONS,
  ),
  isRicosPollPluginEnabled: isExperimentEnabled(
    state,
    EXPERIMENT_RICOS_POLL_PLUGIN,
  ),
  isResolveDownloadUrlWithRicosEnabled: isExperimentEnabled(
    state,
    EXPERIMENT_RESOLVE_DOWNLOAD_URL_WITH_RICOS,
  ),
  isRicosBaseUrlEnabled: isExperimentEnabled(state, EXPERIMENT_RICOS_BASE_URL),
  isTwitterEnabled: getTwitterEnabled(state),
  experiments: getExperiments(state),
  isDemoPost: get(ownProps, 'post.isDemo', false),
  shouldApplyPostDesignInFeed: shouldApplyPostDesignInFeed(state),
  metasiteId: getMetaSiteId(state),
});

export default flowRight(
  connect(mapRuntimeToProps),
  withDeviceType,
  withMediaHosts,
)(RicosViewer);
